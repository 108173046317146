#call-to-action {
  background: $primary-color;
  background-size: cover;
  background-attachment: fixed;
  padding: 80px 0;
  text-align: center;
  position: relative;
  color: $white;
  .block {
    position: relative;
    z-index: 99;
    color: $white;
    h2 {
      margin-bottom: 15px;
      color: $white;
    }
    p {
      font-size: 15px;
      font-weight: 300;
      font-family: 'Roboto', sans-serif;
      margin-top: 20px;
    }
    .btn-contact {
      background: $white;
      border: none;
      color: $primary-color;
      padding: 16px 35px;
      margin-top: 20px;
      font-size: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;
      border-radius: 0;
      i {
        margin-right: 10px;
      }
    }
  }
}
