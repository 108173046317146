
.service-page .service-parts .block {
  margin-bottom: 40px;
  text-align: center;
  i {
    font-size: 35px;
    color: $primary-color;
  }
  p {
    padding: 0 8px;
    font-size: 14px;
    color: #777;
    line-height: 1.7;
  }
}

.works.service-page figure .buttons {
  position: absolute;
  top: 45%;
  left: 13%;
}
