.wrapper_404 {
  h1 {
    font-size: 200px;
    color: $primary-color;
    line-height: 1;
  }
  h2 {
    font-size: 50px;
    margin-top: 0;
  }
  .btn-home {
    background: #414141;
    border: none;
    color: $white;
    padding: 20px 35px;
    margin-top: 10px;
    font-size: 16px;
    font-size: 13px;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: normal;
    border-radius: 0;
  }
}
