#about {
  padding: 110px 0;
  .block {
    padding: 20px 30px 0 30px;
    h2 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 30px;
      text-transform: uppercase;
    }
    p {
      color: #727272;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 35px;
    }
    img {
      max-width: 100%;
    }
  }
}

.about-feature {
  margin-top: 50px;
  .block {
    color: $white;
    width: 33.33%;
    padding: 5%;
    float: left;
    p {
      font-weight: 300;
    }
  }
  .about-feature-1 {
    background: $primary-color;
  }
  .about-feature-2 {
    background: #00B0C7;
  }
  .about-feature-3 {
    background: #00A6BB;
  }
}