
.navbar-default .navbar-nav li a:hover {
  color: $primary-color;
}

.navbar.navbar-default {
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  .navbar-toggle {
    margin-top: 32px;
  }
}

.navbar-header .navbar-brand {
  padding: 5px 0;
  a {
    height: auto;
    display: inline-block;
    margin-top: 8px;
  }
}

.navbar-inverse .navbar-toggle {
  &:focus {
    background: #444;
  }
  background: #444;
}

#top-bar {
  background: $white;
  color: $white;
  -webkit-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  padding: 15px 0;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #dedede;
  .navbar-nav li a:hover {
    background: transparent;
  }
  &.animated-header {
    padding: 20px 0;
    background: $white;
    box-shadow: none;
  }
  .main-menu li {
    a {
      color: #444;
      font-size: 14px;
      font-weight: 500;
      border-bottom: 2px solid transparent;
      -webkit-transition: .3s all;
      -o-transition: .3s all;
      transition: .3s all;
      text-transform: uppercase;
      &:hover {
        color: $primary-color;
      }
    }
    position: relative;
  }
}

.navbar-custom .nav li a {
  position: relative;
  color: $black;
}

.navbar-right .dropdown-menu {
  right: auto;
  left: 0;
}

.navbar-custom .dropdown-menu {
  position: absolute;
  display: block;
  visibility: hidden;
  opacity: 0;
}

.navbar-toggle {
  .icon-bar {
    background: $white;
  }
  background: $black;
}

.dropdown-menu ul {
  list-style: none;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: -5px;
  background: $white;
  background-color: rgba(0, 0, 0, 0.95);
  box-shadow: 0 2px 0.09px rgba(0, 0, 0, 0.15);
}

.nav .open a {
  background-color: transparent;
  &:focus {
    background-color: transparent;
  }
}

#top-bar .dropdown-menu ul li a {
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 8px 0;
  color: $white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}