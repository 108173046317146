@import url(http://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700);
@import url(http://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700);
@import url(http://fonts.googleapis.com/css?family=Glegoo);

body {
  font-family: $primary-font;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $secondary-font;
}

h2 {
  font-size: 26px;
}

p {
  font-family: $primary-font;
  line-height: 22px;
  font-size: 16px;
  font-weight: 300;
}