
.gallery {
  padding: 100px;
  figure .buttons {
    left: 40%;
  }
}

.portfolio-meta span {
  margin: 5px 10px;
  font-weight: 300;
  a {
    color: $white;
  }
}

.portfolio-single {
  padding: 80px 0 20px;
  .portfolio-content {
    padding: 40px 0;
  }
}

.single-post {
  padding: 80px 0 20px;
  .post-content {
    padding: 40px 0;
    ol li, ul li {
      color: #666;
    }
  }
  .media {
    border: 1px solid #dedede;
    padding: 30px 20px;
    .media-body a {
      color: $primary-color;
      font-size: 12px;
    }
    .media {
      border: none;
    }
  }
  .comments {
    margin-top: 40px;
  }
  .post-comment {
    margin-top: 40px;
    h3 {
      margin-bottom: 15px;
    }
    .form-control {
      box-shadow: none;
      border-radius: 0;
    }
    .btn-send {
      background: $primary-color;
      color: $white;
      border-radius: 0;
    }
  }
}
