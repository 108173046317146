#blog-full-width {
  padding: 40px 0 80px;
}

article {
  padding: 30px 0;
}

.blog-content {
  h2 {
    font-family: 'Roboto', sans-serif;
    a {
      color: #444;
      font-weight: 400;
      font-size: 30px;
    }
  }
  .blog-meta {
    color: #9a9a9a;
    font-weight: 300;
    margin-bottom: 25px;
    span:after {
      content: '/';
      padding: 0 3px 0 6px;
    }
    a {
      color: #acacac;
    }
    p {
      margin-top: 20px;
    }
  }
  .btn-details {
    color: $primary-color;
    padding: 0;
    background: $primary-color;
    color: $white;
    padding: 8px 18px;
    border-radius: 0;
    margin-top: 15px;
    border: 1px solid $white;
    box-shadow: 0px 0px 0px 1px $primary-color;
  }
}

.blog-post-image {
  margin-top: 30px;
}

.sidebar {
  padding-top: 60px;
  .widget {
    margin-bottom: 40px;
    padding: 15px;
    border: 1px solid #dedede;
    h3 {
      margin-top: 8px;
    }
  }
  .search {
    input.form-control {
      border-right: 0;
      border-radius: 0;
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
    }
    button {
      border-left: 0;
      &:hover {
        background: transparent;
      }
    }
  }
  .author {
    .author-img img {
      width: 90px;
      border-radius: 100%;
      margin-top: -40px;
      border: 3px solid $white;
    }
    .author-bio p {
      font-size: 14px;
      color: #888;
    }
  }
  .categories ul li {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
    list-style: none;
    span.badge {
      float: right;
      background: transparent;
      color: #444;
      border: 1px solid #dedede;
      border-radius: 0;
    }
    a {
      color: #555;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .recent-post {
    ul li {
      margin: 20px 0;
      a {
        color: #555;
        font-size: 15px;
        &:hover {
          color: $primary-color;
        }
      }
    }
    time {
      font-weight: 300;
      color: #999;
      font-size: 12px;
    }
  }
}
