#clients {
  padding: 30px 0 60px;
  .owl-carousel .owl-item {
    img {
      display: block;
      width: 100%;
      -webkit-transform-style: preserve-3d;
    }
    margin-right: 10px;
  }
}

